@import 'src/assets/styles/variables';

.cc-menu-btn {
  background: transparent;
  color: $shark;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    color: $pitch-black;
  }
}
