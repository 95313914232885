@import "@aviatar/bootstrap/src/scss/bootstrap";

.loading-spinner-circle {
  display: inline-block;
  position: relative;
  top: 2px;
  color: inherit;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  text-indent: -9999em;
  border: 2px solid rgba(66, 66, 66, .2);
  border-left-color: $purple-medium;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 .8s linear infinite;
  animation: load8 .8s linear infinite;

  &.xl {
    width: 48px;
    height: 48px;
  }

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
