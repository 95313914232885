$icomoon-font-family: "aviatar-icons" !default;
$aviatar-icons-font-path: "fonts" !default;
$icomoon-font-path: $aviatar-icons-font-path;

$acreg: "\e907";
$airborne: "\e900";
$airport: "\e912";
$anti-ice: "\e917";
$ata-chapter: "\e911";
$cabin-item: "\e918";
$case-link: "\e927";
$check: "\e90c";
$cockpit-item: "\e919";
$complaint-history: "\e91a";
$component-change: "\e929";
$cruise: "\e901";
$deferred-items: "\e91b";
$engine: "\e90b";
$engine-2: "\e91f";
$engine-position: "\e921";
$engine-serial-number: "\e920";
$fleet: "\e909";
$flight-data: "\e91c";
$flight-phase: "\e902";
$flight-schedule: "\e915";
$fluid-uplift: "\e91d";
$fuel-uplift: "\e91e";
$heart: "\e903";
$hourglass: "\e913";
$landing-gear: "\e914";
$logo: "\e904";
$manager: "\e90f";
$manufacture: "\e90a";
$matrix-view: "\e905";
$operator: "\e908";
$predict: "\e930";
$predictor-plugins: "\e928";
$progress-open: "\e931";
$propeller: "\e90e";
$service: "\e90d";
$taxi: "\e916";
$tower: "\e910";
$unicorn: "\e906";

