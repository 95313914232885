.accordion-button {
  font-weight: 500;

  // bring icon to left
  &::before {
    flex-shrink: 0;
    width: var(--avi-accordion-btn-icon-width);
    height: var(--avi-accordion-btn-icon-width);
    margin-right: 12px;
    content: "";
    background-image: var(--avi-accordion-btn-icon);
    transform: rotate(-90deg);
    background-repeat: no-repeat;
    background-size: var(--avi-accordion-btn-icon-width);
    transition: var(--avi-accordion-btn-icon-transition);
  }
  &:not(.collapsed) {
    color: var(--#{$prefix}accordion-active-color);
    background-color: var(--#{$prefix}accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--#{$prefix}accordion-border-width)) 0 var(--#{$prefix}accordion-border-color);
    &::before {
      background-image: var(--#{$prefix}accordion-btn-active-icon);
      transform: var(--#{$prefix}accordion-btn-icon-transform);
    }
  }
  &::after {
    display: none;
  }
}

.accordion.no-border {
  --avi-accordion-border-color: none;
  .accordion-button {
    box-shadow: $box-shadow;
    margin-bottom: 16px
  }
  .accordion-body {
    padding-top: 0;
  }
}

.accordion.accordion-white {
  .accordion-button {
    background-color: $white;
  }
  .accordion-item,.accordion-body {
    background-color: $alabaster;
  }
}
