.navbar {
  .navbar-brand {
    font-weight: 500;
  }

  // overwrite default responsive behavior of navbar and don't break to vertical menu items
  .navbar-nav-scroll {
    overflow-y: hidden;
  }
  .navbar-nav {
    flex-direction: row;
    .nav-link {
      padding-right: var(--#{$prefix}navbar-nav-link-padding-x);
      padding-left: var(--#{$prefix}navbar-nav-link-padding-x);
    }
  }

  .navbar-collapse {
    flex-basis: auto;
  }

  .nav-item {
    position: relative;
    .nav-link {
      &.active {
        &::after {
          content: "";
          border-bottom: 2px solid $marine;
          border-radius: 8px;
          display: block;
          position: absolute;
          bottom: -5px;
          left: 9px;
          right: 9px;
          height: 2px;
        }
      }
    }
  }
}

.navbar-dark {
  .nav-item {
    .nav-link {
      &.active {
        &::after {
          content: "";
          border-bottom: 2px solid $white;
          border-radius: 8px;
          display: block;
          position: absolute;
          bottom: -5px;
          left: 9px;
          right: 9px;
          height: 2px;
        }
      }
    }
  }
}
