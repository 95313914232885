.table {
  > thead {
    border-bottom: 2px solid $spray;
  }
}

.table-active {
  &:hover {
    --avi-table-hover-bg: var(--avi-table-active-bg);
    > * {
      color: $white !important;
    }
  }
}
