.card {
  $avi-card-bg-scale: -80%;

  box-shadow: $card-box-shadow; // we could also set $enable-shadow globally, but this would break other components which should not have shadows.

  .card-header {
    border-bottom: 1px solid $gray-medium;
  }

  @each $color, $value in $theme-colors {
    &.border-#{$color} {
      border-width: 0 0 0 4px;
    }
    &.bg-#{$color} {
      background-color: shift-color($value, $avi-card-bg-scale) !important; // overwrite default background color with a more subtle color
    }
  }
}

// If a card is a button, then it has a hover effect
.card[role="button"] {
  @include transition(.25s cubic-bezier(0, 1, 0, 1), box-shadow .25s ease-in);

  &:hover {
    box-shadow: $avi-box-shadow-hover;
  }

  &:active {
    background: $gray-400;
  }
}
