// Set basic site header heights derived property
@mixin avi-site-header-heights-derived-property ($property: "padding-top") {
  @each $breakpoint in map-keys($avi-site-header-nav-primary-heights) {
    @include media-breakpoint-up($breakpoint) {
      #{$property}: avi-site-header-height($breakpoint);
    }
  }
}

// Set basic two staged site header heights derived property
@mixin avi-site-header-two-stages-heights-derived-property ($property: "padding-top") {
  @each $breakpoint in map-keys($avi-site-header-nav-primary-two-stages-heights) {
    @include media-breakpoint-up($breakpoint) {
      #{$property}: avi-site-header-two-stages-height($breakpoint);
    }
  }
}

// Basic site header successor derivative
// Used to set position-top in @aviatar/launchpad, @aviatar/vanilla-launchpad
@mixin avi-site-header-basic-height-successor-derivative ($combinator: "~", $property: "padding-top") {

  @each $breakpoint in xs md lg {

    @include media-breakpoint-up($breakpoint) {

      .avi-site-header.avi-site-header-has-nav-primary #{$combinator} &,
      .avi-site-header.avi-site-header-has-nav-primary-two-stages #{$combinator} & {
        // Single row
        #{$property}: avi-site-header-height($breakpoint);
      }
    }
  }
}

// Extended site header successor derivative
// Used to apply appropriate `padding-top` in the site content section in
// ../_site-layout.scss
@mixin avi-site-header-extended-height-successor-derivative ($combinator: "~", $property: "padding-top") {

  @each $breakpoint in xs md lg {

    @include media-breakpoint-up($breakpoint) {

      // Single row
      .avi-site-header.avi-site-header-has-nav-primary #{$combinator} & {
        #{$property}: avi-site-header-height($breakpoint);
      }

      // Single row
      .avi-site-header.avi-site-header-has-nav-secondary #{$combinator} & {
        #{$property}: $avi-site-header-border-bottom-width + map-get($avi-site-header-nav-secondary-heights, $breakpoint);
      }

      // Single row
      .avi-site-header.avi-site-header-has-nav-tertiary #{$combinator} & {
        #{$property}: $avi-site-header-border-bottom-width + map-get($avi-site-header-nav-tertiary-heights, $breakpoint);
      }

      // Single row + single row
      .avi-site-header.avi-site-header-has-nav-primary.avi-site-header-has-nav-secondary #{$combinator} & {
        #{$property}: $avi-site-header-border-bottom-width + map-get($avi-site-header-nav-primary-heights, $breakpoint) + map-get($avi-site-header-nav-secondary-heights, $breakpoint);
      }

      // Single row + single row
      .avi-site-header.avi-site-header-has-nav-primary.avi-site-header-has-nav-tertiary #{$combinator} & {
        #{$property}: $avi-site-header-border-bottom-width + map-get($avi-site-header-nav-primary-heights, $breakpoint) + map-get($avi-site-header-nav-tertiary-heights, $breakpoint);
      }

      // Single row + single row
      .avi-site-header.avi-site-header-has-nav-secondary.avi-site-header-has-nav-tertiary #{$combinator} & {
        #{$property}: $avi-site-header-border-bottom-width + map-get($avi-site-header-nav-secondary-heights, $breakpoint) + map-get($avi-site-header-nav-tertiary-heights, $breakpoint);
      }

      // Single row + single row + single row
      .avi-site-header.avi-site-header-has-nav-primary.avi-site-header-has-nav-secondary.avi-site-header-has-nav-tertiary #{$combinator} & {
        #{$property}: $avi-site-header-border-bottom-width + map-get($avi-site-header-nav-primary-heights, $breakpoint) + map-get($avi-site-header-nav-secondary-heights, $breakpoint) + map-get($avi-site-header-nav-tertiary-heights, $breakpoint);
      }

      // Forced 2 rows wrapped on mobile, single row on `up(lg)`
      .avi-site-header.avi-site-header-has-nav-primary-two-stages #{$combinator} & {
        #{$property}: avi-site-header-two-stages-height($breakpoint);
      }

      // Forced 2 rows wrapped on mobile, single row on `up(lg)` + single row
      .avi-site-header.avi-site-header-has-nav-primary-two-stages.avi-site-header-has-nav-secondary #{$combinator} & {
        #{$property}: $avi-site-header-border-bottom-width + map-get($avi-site-header-nav-primary-two-stages-heights, $breakpoint) + map-get($avi-site-header-nav-secondary-heights, $breakpoint);
      }

      // Forced 2 rows wrapped on mobile, single row on `up(lg)` + single row
      .avi-site-header.avi-site-header-has-nav-primary-two-stages.avi-site-header-has-nav-tertiary #{$combinator} & {
        #{$property}: $avi-site-header-border-bottom-width + map-get($avi-site-header-nav-primary-two-stages-heights, $breakpoint) + map-get($avi-site-header-nav-tertiary-heights, $breakpoint);
      }

      // Forced 2 rows wrapped on mobile, single row on `up(lg)` + single row + single row
      .avi-site-header.avi-site-header-has-nav-primary-two-stages.avi-site-header-has-nav-secondary.avi-site-header-has-nav-tertiary #{$combinator} & {
        #{$property}: $avi-site-header-border-bottom-width + map-get($avi-site-header-nav-primary-two-stages-heights, $breakpoint) + map-get($avi-site-header-nav-secondary-heights, $breakpoint) + map-get($avi-site-header-nav-tertiary-heights, $breakpoint);
      }
    }
  }
}
