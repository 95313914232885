.form-control:focus {
  &.is-valid {
    box-shadow: 0 0 4px $leaves;
  }
  &.is-invalid {
    box-shadow: 0 0 4px $red;
  }
}

.form-floating > label {
  color: $asphalt;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233E4080'/%3e%3c/svg%3e");
  &:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  }
}

.form-range:hover::-webkit-slider-thumb, .form-range:active::-webkit-slider-thumb {
  background-color: $marine;
}

.form-select, .form-select:focus {
  &[multiple] {
    padding-left: 0;
    option {
      padding-left: $form-select-padding-x;
    }
  }

  option {
    color: $marine;
    &:checked {
      background-color: $cloud !important;
      color: $deep-blue !important;
    }
  }
}

textarea::-webkit-resizer {
  content: '';
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDIuMDAwMTZIMTBWMEg4VjIuMDAwMTZaTTggNi4wMDAzNkgxMFY0LjAwMDA1SDhWNi4wMDAzNlpNOCAxMC4wMDAzSDEwVjguMDAwMTFIOFYxMC4wMDAzWk02IDEwLjAwMDNINFY4LjAwMDExSDZWMTAuMDAwM1pNNCA2LjAwMDM2SDZWNC4wMDAwNUg0VjYuMDAwMzZaTTIgMTAuMDAwM0gwVjguMDAwMTFIMlYxMC4wMDAzWiIgZmlsbD0iIzNFNDA4MCIvPgo8L3N2Zz4K');
  background-repeat: no-repeat;
}
