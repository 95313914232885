// Color system

// scss-docs-start gray-color-variables
$white: #fff;
$gray-100: #FAFAFA;
$gray-200: #F2F2F2;
$gray-300: #E8E8E8;
$gray-400: #D8D8D8;
$gray-500: #B2BABD;
$gray-600: #7A7D82;
$gray-700: #626569;
$gray-800: #333436;
$gray-900: #262729;
$black: #000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
);
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #4C9DB8;
$dark-blue: #396297;
$indigo: #29066B;
$purple: #676BD6;
$pink: #EB548C;
$red: #BF5557;
$orange: #EE9A3A;
$yellow: #F2C722;
$green: #4EBD9F;
$cyan: #1DE4BD;
$teal: #1AC9E6;
// scss-docs-end color-variables

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black;
$color-contrast-light: $white;

// fusv-disable
$blue-100: tint-color($blue, 80%);
$blue-200: tint-color($blue, 60%);
$blue-300: tint-color($blue, 40%);
$blue-400: tint-color($blue, 20%);
$blue-500: $blue;
$blue-600: shade-color($blue, 20%);
$blue-700: shade-color($blue, 40%);
$blue-800: shade-color($blue, 60%);
$blue-900: shade-color($blue, 80%);

$dark-blue-100: #F5F6F8;
$dark-blue-200: #DDE4ED;
$dark-blue-300: #CAD6E6;
$dark-blue-400: #7D9EC7;
$dark-blue-500: $dark-blue;
$dark-blue-600: #294973;
$dark-blue-700: #203959;
$dark-blue-800: #1A2E47;
$dark-blue-900: #132235;

$indigo-100: tint-color($indigo, 80%);
$indigo-200: tint-color($indigo, 60%);
$indigo-300: tint-color($indigo, 40%);
$indigo-400: tint-color($indigo, 20%);
$indigo-500: $indigo;
$indigo-600: shade-color($indigo, 20%);
$indigo-700: shade-color($indigo, 40%);
$indigo-800: shade-color($indigo, 60%);
$indigo-900: shade-color($indigo, 80%);

$purple-100: tint-color($purple, 80%);
$purple-200: tint-color($purple, 60%);
$purple-300: tint-color($purple, 40%);
$purple-400: tint-color($purple, 20%);
$purple-500: $purple;
$purple-600: shade-color($purple, 20%);
$purple-700: shade-color($purple, 40%);
$purple-800: shade-color($purple, 60%);
$purple-900: shade-color($purple, 80%);

$pink-100: tint-color($pink, 80%);
$pink-200: tint-color($pink, 60%);
$pink-300: tint-color($pink, 40%);
$pink-400: tint-color($pink, 20%);
$pink-500: $pink;
$pink-600: shade-color($pink, 20%);
$pink-700: shade-color($pink, 40%);
$pink-800: shade-color($pink, 60%);
$pink-900: shade-color($pink, 80%);

$red-100: #FCEAE6;
$red-200: #FFCCCA;
$red-300: #EAA5A3;
$red-400: #DF7E7A;
$red-500: $red;
$red-600: shade-color($red, 20%);
$red-700: shade-color($red, 40%);
$red-800: shade-color($red, 60%);
$red-900: shade-color($red, 80%);

$orange-100: tint-color($orange, 80%);
$orange-200: tint-color($orange, 60%);
$orange-300: tint-color($orange, 40%);
$orange-400: tint-color($orange, 20%);
$orange-500: $orange;
$orange-600: shade-color($orange, 20%);
$orange-700: shade-color($orange, 40%);
$orange-800: shade-color($orange, 60%);
$orange-900: shade-color($orange, 80%);

$yellow-100: #FFF9E3;
$yellow-200: #FEF0B8;
$yellow-300: #FFEA94;
$yellow-400: #FFDC53;
$yellow-500: $yellow;
$yellow-600: shade-color($yellow, 20%);
$yellow-700: shade-color($yellow, 40%);
$yellow-800: shade-color($yellow, 60%);
$yellow-900: shade-color($yellow, 80%);

$green-100: tint-color($green, 80%);
$green-200: tint-color($green, 60%);
$green-300: tint-color($green, 40%);
$green-400: tint-color($green, 20%);
$green-500: $green;
$green-600: #43A38A;
$green-700: #2E705F;
$green-800: #1B5747;
$green-900: #143830;

$teal-100: tint-color($teal, 80%);
$teal-200: tint-color($teal, 60%);
$teal-300: tint-color($teal, 40%);
$teal-400: tint-color($teal, 20%);
$teal-500: $teal;
$teal-600: shade-color($teal, 20%);
$teal-700: shade-color($teal, 40%);
$teal-800: shade-color($teal, 60%);
$teal-900: shade-color($teal, 80%);

$cyan-100: tint-color($cyan, 80%);
$cyan-200: tint-color($cyan, 60%);
$cyan-300: tint-color($cyan, 40%);
$cyan-400: tint-color($cyan, 20%);
$cyan-500: $cyan;
$cyan-600: shade-color($cyan, 20%);
$cyan-700: shade-color($cyan, 40%);
$cyan-800: shade-color($cyan, 60%);
$cyan-900: shade-color($cyan, 80%);

// AVIATAR additional colors
$ocean: #142459;
$malibu: #19AADE;
$denim: #176BA0;
$dove: #A5B3D1;
$spindle: #ACC6EA;
$crimson: #DC322F;
$coral: #EA7369;
$mango: #FF8100;
$amethyst: #7D3AC1;
$lilac: #AF4BCE;
$carissma: #DB4CB2;
$beige: #DBB381;
$quicksand: #E7D8C4;
$pitch-black: #212121;
$laurel: #05A318;
$chenin: #DBDE86;
// purples
$purple-pale: $purple-100;
$purple-pastel: $purple-200;
$purple-bright: $purple-300;
$periwinkle: $purple-400;
$purple-medium: $purple-500;
$thanos: $purple-600;
$purple-dark: $purple-700;
$deep-purple: $purple-800;
$kilgrave: $purple-900;
// dark-blues
$airborne: $dark-blue-100;
$cloud: $dark-blue-200;
$crane: $dark-blue-300;
$spray: $dark-blue-400;
$marine: $dark-blue-500;
$wave: $dark-blue-600;
$deep-blue: $dark-blue-700;
$dark-ocean: $dark-blue-800;
$abyss: $dark-blue-900;
// greens
$green-pale: $green-100;
$green-pastel: $green-200;
$green-bright: $green-300;
$jade: $green-400;
$leaves: $green-600;
$green-dark: $green-700;
$seaweed: $green-800;
$pond: $green-900;
// yellows
$yellow-pale: $yellow-100;
$yellow-pastel: $yellow-200;
$yellow-bright: $yellow-300;
$sunny: $yellow-400;
$honey: $yellow;
$mustard: $yellow-600;
$yellow-dark: $yellow-700;
$ochre: $yellow-800;
$brownish: $yellow-900;
// reds
$salmon: $red-100;
$geraldine: $red-200;
$red-bright: $red-300;
$rose: $red-400;
$dirty-red: $red-600;
$deadpool: $red-700;
$burgundy: $red-800;
$maroon: $red-900;
// blues
$blue-pale: $blue-100;
$blue-pastel: $blue-200;
$aqua: $blue-300;
$river: $blue-400;
$north-sea: $blue-600;
$petrol: $blue-700;
$storm: $blue-800;
$shearwater: $blue-900;
// teal
$sky-blue: $teal-100;
$aquamarine: $teal-200;
$pacific: $teal-300;
$mediterranean: $teal-400;
$carribean: $teal-500;
$atlantic: $teal-600;
$teal-dark: $teal-700;
$baltic-sea: $teal-800;
$black-sea: $teal-900;
// indigo
$indigo-pale: $indigo-100;
$prelude: $indigo-200;
$bright-indigo: $indigo-300;
$bright-violet: $indigo-400;
$violet: $indigo-500;
$deep-violet: $indigo-600;
$indigo-dark: $indigo-700;
$aubergine: $indigo-800;
$black-indigo: $indigo-900;
// pink
$pink-pale: $pink-100;
$lavender: $pink-200;
$pink-bright: $pink-300;
$doll: $pink-400;
$flamingo: $pink-500;
$hibiskus: $pink-600;
$pink-dark: $pink-700;
$berry: $pink-800;
$black-pink: $pink-900;
// orange
$pale-orange: $orange-100;
$buckthorn: $orange-200;
$bright-orange: $orange-300;
$clementine: $orange-400;
$copper: $orange-600;
$orange-dark: $orange-700;
$rusty: $orange-800;
$medley: $orange-900;
// cyan
$cyan-light: $cyan-100;
$cyan-pastel: $cyan-200;
$mint: $cyan-300;
$may: $cyan-400;
$emerald: $cyan-600;
$cyan-dark: $cyan-700;
$deep-cyan: $cyan-800;
$forest: $cyan-900;
// gray
$alabaster: $gray-100;
$concrete: $gray-200;
$mercury: $gray-300;
$gray-medium: $gray-400;
$granite: $gray-500;
$asphalt: $gray-600;
$anthracite: $gray-700;
$shark: $gray-800;
$mole: $gray-900;

// scss-docs-start colors-map
$colors: (
        "blue": $blue,
        "dark-blue": $dark-blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "cyan": $cyan,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "black": $black,
        "white": $white,
  // additional AVIATAR colors
        "ocean": $ocean,
        "malibu": $malibu,
        "denim": $denim,
        "dove": $dove,
        "spindle": $spindle,
        "crimson": $crimson,
        "coral": $coral,
        "mango": $mango,
        "amethyst": $amethyst,
        "lilac": $lilac,
        "carissma": $carissma,
        "beige": $beige,
        "quicksand": $quicksand,
        "pitch-black": $pitch-black,
        "laurel": $laurel,
        "chenin": $chenin,
        // purple
        "purple-pale": $purple-pale,
        "purple-pastel": $purple-pastel,
        "purple-bright": $purple-bright,
        "periwinkle": $periwinkle,
        "purple-medium": $purple-medium,
        "thanos": $thanos,
        "purple-dark": $purple-dark,
        "deep-purple": $deep-purple,
        "kilgrave": $kilgrave,
        // dark-blues
        "airborne": $airborne,
        "cloud": $cloud,
        "crane": $crane,
        "spray": $spray,
        "marine": $marine,
        "wave": $wave,
        "deep-blue": $deep-blue,
        "dark-ocean": $dark-ocean,
        "abyss": $abyss,
        // green
        "green-pale": $green-pale,
        "green-pastel": $green-pastel,
        "green-bright": $green-bright,
        "jade": $jade,
        "leaves": $leaves,
        "green-dark": $green-dark,
        "seaweed": $seaweed,
        "pond": $pond,
        // yellow
        "yellow-pale": $yellow-pale,
        "yellow-pastel": $yellow-pastel,
        "yellow-bright": $yellow-bright,
        "sunny": $sunny,
        "honey": $honey,
        "mustard": $mustard,
        "yellow-dark": $yellow-dark,
        "ochre": $ochre,
        "brownish": $brownish,
        // reds
        "salmon": $salmon,
        "geraldine": $geraldine,
        "red-bright": $red-bright,
        "rose": $rose,
        "dirty-red": $dirty-red,
        "deadpool": $deadpool,
        "burgundy": $burgundy,
        "maroon": $maroon,
        // blues
        "blue-pale": $blue-pale,
        "blue-pastel": $blue-pastel,
        "aqua": $aqua,
        "river": $river,
        "north-sea": $north-sea,
        "petrol": $petrol,
        "storm": $storm,
        "shearwater": $shearwater,
        // teal
        "sky-blue": $sky-blue,
        "aquamarine": $aquamarine,
        "pacific": $pacific,
        "mediterranean": $mediterranean,
        "carribean": $carribean,
        "atlantic": $atlantic,
        "teal-dark": $teal-dark,
        "baltic-sea": $baltic-sea,
        "black-sea": $black-sea,
        // ingigo
        "indigo-pale": $indigo-pale,
        "prelude": $prelude,
        "bright-indigo": $bright-indigo,
        "bright-violet": $bright-violet,
        "violet": $violet,
        "deep-violet": $deep-violet,
        "indigo-dark": $indigo-dark,
        "aubergine": $aubergine,
        "black-indigo": $black-indigo,
        // pink
        "pink-pale": $pink-pale,
        "lavender": $lavender,
        "pink-bright": $pink-bright,
        "doll": $doll,
        "flamingo": $flamingo,
        "hibiskus": $hibiskus,
        "pink-dark": $pink-dark,
        "berry": $berry,
        "black-pink": $black-pink,
        // orange
        "pale-orange": $pale-orange,
        "buckthorn": $buckthorn,
        "bright-orange": $bright-orange,
        "clementine": $clementine,
        "copper": $copper,
        "orange-dark": $orange-dark,
        "rusty": $rusty,
        "medley": $medley,
        // cyan
        "cyan-light": $cyan-light,
        "cyan-pastel": $cyan-pastel,
        "mint": $mint,
        "may": $may,
        "emerald": $emerald,
        "cyan-dark": $cyan-dark,
        "deep-cyan": $deep-cyan,
        "forest": $forest,
        // gray
        "alabaster": $alabaster,
        "concrete": $concrete,
        "mercury": $mercury,
        "gray-medium": $gray-medium,
        "granite": $granite,
        "asphalt": $asphalt,
        "anthracite": $anthracite,
        "shark": $shark,
        "mole": $mole,

);

// scss-docs-end colors-map


$blues: (
        "blue-100": $blue-100,
        "blue-200": $blue-200,
        "blue-300": $blue-300,
        "blue-400": $blue-400,
        "blue-500": $blue-500,
        "blue-600": $blue-600,
        "blue-700": $blue-700,
        "blue-800": $blue-800,
        "blue-900": $blue-900
);

$dark-blues: (
        "dark-blue-100": $dark-blue-100,
        "dark-blue-200": $dark-blue-200,
        "dark-blue-300": $dark-blue-300,
        "dark-blue-400": $dark-blue-400,
        "dark-blue-500": $dark-blue-500,
        "dark-blue-600": $dark-blue-600,
        "dark-blue-700": $dark-blue-700,
        "dark-blue-800": $dark-blue-800,
        "dark-blue-900": $dark-blue-900
);

$indigos: (
        "indigo-100": $indigo-100,
        "indigo-200": $indigo-200,
        "indigo-300": $indigo-300,
        "indigo-400": $indigo-400,
        "indigo-500": $indigo-500,
        "indigo-600": $indigo-600,
        "indigo-700": $indigo-700,
        "indigo-800": $indigo-800,
        "indigo-900": $indigo-900
);

$purples: (
        "purple-100": $purple-100,
        "purple-200": $purple-200,
        "purple-300": $purple-300,
        "purple-400": $purple-400,
        "purple-500": $purple-500,
        "purple-600": $purple-600,
        "purple-700": $purple-700,
        "purple-800": $purple-800,
        "purple-900": $purple-900
);

$pinks: (
        "pink-100": $pink-100,
        "pink-200": $pink-200,
        "pink-300": $pink-300,
        "pink-400": $pink-400,
        "pink-500": $pink-500,
        "pink-600": $pink-600,
        "pink-700": $pink-700,
        "pink-800": $pink-800,
        "pink-900": $pink-900
);

$reds: (
        "red-100": $red-100,
        "red-200": $red-200,
        "red-300": $red-300,
        "red-400": $red-400,
        "red-500": $red-500,
        "red-600": $red-600,
        "red-700": $red-700,
        "red-800": $red-800,
        "red-900": $red-900
);

$oranges: (
        "orange-100": $orange-100,
        "orange-200": $orange-200,
        "orange-300": $orange-300,
        "orange-400": $orange-400,
        "orange-500": $orange-500,
        "orange-600": $orange-600,
        "orange-700": $orange-700,
        "orange-800": $orange-800,
        "orange-900": $orange-900
);

$yellows: (
        "yellow-100": $yellow-100,
        "yellow-200": $yellow-200,
        "yellow-300": $yellow-300,
        "yellow-400": $yellow-400,
        "yellow-500": $yellow-500,
        "yellow-600": $yellow-600,
        "yellow-700": $yellow-700,
        "yellow-800": $yellow-800,
        "yellow-900": $yellow-900
);

$greens: (
        "green-100": $green-100,
        "green-200": $green-200,
        "green-300": $green-300,
        "green-400": $green-400,
        "green-500": $green-500,
        "green-600": $green-600,
        "green-700": $green-700,
        "green-800": $green-800,
        "green-900": $green-900
);

$teals: (
        "teal-100": $teal-100,
        "teal-200": $teal-200,
        "teal-300": $teal-300,
        "teal-400": $teal-400,
        "teal-500": $teal-500,
        "teal-600": $teal-600,
        "teal-700": $teal-700,
        "teal-800": $teal-800,
        "teal-900": $teal-900
);

$cyans: (
        "cyan-100": $cyan-100,
        "cyan-200": $cyan-200,
        "cyan-300": $cyan-300,
        "cyan-400": $cyan-400,
        "cyan-500": $cyan-500,
        "cyan-600": $cyan-600,
        "cyan-700": $cyan-700,
        "cyan-800": $cyan-800,
        "cyan-900": $cyan-900
);
// fusv-enable

// scss-docs-start theme-color-variables
$primary: $purple;
$secondary: $dark-blue;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark
);
// scss-docs-end theme-colors-map
