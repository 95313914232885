.popover,
.tooltip-inner {
  box-shadow: $box-shadow-lg;
}

.bs-tooltip-right .arrow::after {
  content: "";
  position: absolute;
  left: 100%;
  z-index: -1;
  border: 5px solid #fff;
  transform-origin: 0 0;
  transform: rotate(45deg);
  box-shadow: $box-shadow;
}

.popover .popover-header {
  border-bottom: 1px solid $mercury;
}
