@import '@ng-select/ng-select/themes/default.theme.css';
@import '@mdi/font/scss/variables';
@import '@aviatar/bootstrap/src/scss/helpers';

.ng-select .ng-select-container {
  border-radius: $border-radius;

  .ng-arrow-wrapper {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid $shark;
    border-top-color: $purple-pastel;
    padding-right: 0;
    width: 2.25rem;

    & .ng-arrow {
      top: 0.125rem;
    }
  }

  .ng-value-container .ng-placeholder {
    color: $shark;
  }

  &:hover {
    .ng-arrow-wrapper {
      background-color: $mercury;
    }

    .ng-arrow {
      border-top-color: $purple-dark;
    }
  }

  .ng-clear-wrapper {
    display: none;
  }
}

.ng-select .ng-dropdown-panel .ng-option.ng-option-disabled {
  cursor: not-allowed;
}

.ng-select.ng-select-opened .ng-select-container {
  .ng-arrow {
    border-top-color: transparent;
    border-bottom-color: $purple-medium;
    top: 0;
  }

  &:hover .ng-arrow {
    border-top-color: transparent;
    border-bottom-color: $purple-dark;
  }
}

.ng-select.ng-select-multiple .ng-select-container {
  border-color: $shark;

  .ng-value-container {
    line-height: 1.4rem;
    background-color: white;
    min-height: 2.125rem;

    & .ng-placeholder {
      top: 0.375rem;
      padding: 0 0.25rem;
    }

    & .ng-input {
      padding: 0;
    }
  }

  //chips
  .ng-value-container .ng-value {
    pointer-events: all;

    background: $purple-medium;
    color: $white;
    font-size: 0.75rem;
    line-height: 1.563rem;
    height: 1.563rem;

    border-radius: 2px;
    padding-left: 6px;

    .ng-value-label {
      padding-left: 0;
      padding-right: 0;
    }

    .ng-value-icon {
      cursor: pointer;
      float: right;
      position: relative;

      font-size: 1.3em;
      line-height: inherit;
      text-shadow: none;
      border-right: 0 !important;

      height: 100%;
      width: 18.75px;
      margin-left: 3px;

      &:after {
        color: $white;
        line-height: 1.563rem;
        visibility: visible;
        font-size: 1.2em;

        padding-left: 3px;
        padding-right: 3px;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }

      &:hover {
        background-color: $purple-dark;
        border-radius: 0 2px 2px 0;
      }
    }
  }

  input {
    min-height: unset;
  }

  .ng-clear-wrapper {
    display: none;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: $purple-dark;
}

//selected
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #ebf5ff;
  font-weight: 500;
  color: $purple-dark;
}
