@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ah3d4a') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ah3d4a') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ah3d4a##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.aviatar-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aviatar-icons {

  &.acreg {
    &:before {
      content: $acreg;
    }
  }
  &.airborne {
    &:before {
      content: $airborne;
    }
  }
  &.airport {
    &:before {
      content: $airport;
    }
  }
  &.anti-ice {
    &:before {
      content: $anti-ice;
    }
  }
  &.ata-chapter {
    &:before {
      content: $ata-chapter;
    }
  }
  &.cabin-item {
    &:before {
      content: $cabin-item;
    }
  }
  &.case-link {
    &:before {
      content: $case-link;
    }
  }
  &.check {
    &:before {
      content: $check;
    }
  }
  &.cockpit-item {
    &:before {
      content: $cockpit-item;
    }
  }
  &.complaint-history {
    &:before {
      content: $complaint-history;
    }
  }
  &.component-change {
    &:before {
      content: $component-change;
    }
  }
  &.cruise {
    &:before {
      content: $cruise;
    }
  }
  &.deferred-items {
    &:before {
      content: $deferred-items;
    }
  }
  &.engine {
    &:before {
      content: $engine;
    }
  }
  &.engine-2 {
    &:before {
      content: $engine-2;
    }
  }
  &.engine-position {
    &:before {
      content: $engine-position
    }
  }
  &.engine-serial-number {
    &:before {
      content: $engine-serial-number
    }
  }
  &.fleet {
    &:before {
      content: $fleet;
    }
  }
  &.flight-data {
    &:before {
      content: $flight-data;
    }
  }
  &.flight-phase {
    &:before {
      content: $flight-phase;
    }
  }
  &.flight-schedule {
    &:before {
      content: $flight-schedule;
    }
  }
  &.fluid-uplift {
    &:before {
      content: $fluid-uplift;
    }
  }
  &.fuel-uplift {
    &:before {
      content: $fuel-uplift;
    }
  }
  &.heart {
    &:before {
      content: $heart;
    }
  }
  &.hourglass {
    &:before {
      content: $hourglass;
    }
  }
  &.landing-gear {
    &:before {
      content: $landing-gear;
    }
  }
  &.logo {
    &:before {
      content: $logo;
    }
  }
  &.manager {
    &:before {
      content: $manager;
    }
  }
  &.manufacture {
    &:before {
      content: $manufacture;
    }
  }
  &.matrix-view {
    &:before {
      content: $matrix-view;
    }
  }
  &.operator {
    &:before {
      content: $operator;
    }
  }
  &.predict {
    &:before {
      content: $predict;
    }
  }
  &.predictor-plugins {
    &:before {
      content: $predictor-plugins;
    }
  }
  &.progress-open {
    &:before {
      content: $progress-open;
    }
  }
  &.propeller {
    &:before {
      content: $propeller;
    }
  }
  &.service {
    &:before {
      content: $service;
    }
  }
  &.taxi {
    &:before {
      content: $taxi;
    }
  }
  &.tower {
    &:before {
      content: $tower;
    }
  }
  &.unicorn {
    &:before {
      content: $unicorn;
    }
  }
}
