.pagination {
  .page-item {
    margin: 0 2px;
    font-weight: 500;

    &.disabled {
      opacity: $btn-disabled-opacity;
    }
  }
}
