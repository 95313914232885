
//
// Tabs
//
.nav-tabs {
  margin-bottom: 1.5em;

  .nav-item {
    position: relative;
    .nav-link {
      &.active {
        &::after {
          content: "";
          border-bottom: 2px solid $dark-blue-500;
          border-radius: 8px;
          display: block;
          position: absolute;
          bottom: 0;
          left: 15px;
          right: 15px;
          height: 2px;
        }
      }
    }
  }
}


//
// Pills
//
.nav-pills {
  .nav-item {
    .nav-link {
      font-weight: normal;
      font-size: 0.875rem;
      padding: 5px;

      &:hover:not(.active) {
        background-color: $cloud;
        color: $shark;
      }
    }
  }
}
