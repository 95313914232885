@import "colors";

$theme: "AVIATAR";
$prefix: "avi-";

// Common
$enable-shadows: false; // don't enable shadows as we only need that for cards
$avi-focus-box-shadow: 0px 0px 4px $periwinkle;

// Body
$body-bg: $white;
$body-color: $black;

// Border radius
$border-radius: 0.125rem;
$border-radius-sm: $border-radius;
$border-radius-lg: $border-radius;
$border-radius-xl: $border-radius;
$border-radius-2xl: $border-radius;
$border-radius-pill: 0.75rem;


//
// Fonts
//

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-size-base: 0.875rem; // Assumes the browser default, typically `16px`
$h1-font-size: 2.25rem;
$h2-font-size: 1.25rem;
$h3-font-size: 1rem;
$h4-font-size: 0.875rem;
$h5-font-size: 0.75rem;
$text-muted: $anthracite;

//
// Links
//
$link-decoration: none;
$link-hover-color: $marine;

//
// Buttons + Forms
//
$btn-link-color: $purple-dark;
$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 0.875rem;
$btn-line-height: 1;
$btn-padding-x: 0.5067rem;
$btn-padding-y: 0.625rem;
$btn-font-size: 0.875rem;
$btn-padding-x-lg: 0.845rem;
$btn-padding-y-lg: 0.875rem;
$btn-font-size-lg: 0.875rem;
$btn-font-size-sm: 0.75rem;
$btn-padding-x-sm: 0.3rem;
$btn-padding-y-sm: 0.375rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-padding-y-sm: 0.375rem;
$input-border-color: $gray-600;
$input-focus-border-color: $crane;
$btn-close-padding-x: .6rem;
$btn-close-focus-shadow: none;
$btn-close-width: 0.6rem;
$btn-disabled-opacity: 0.5;
$input-placeholder-color: $asphalt;
$form-label-color: $shark;
$input-height-lg: 44px;
$input-padding-x: 0.5067rem;
$input-padding-y: 0.4067rem;
$input-padding-x-lg: $input-padding-x;
$input-padding-y-lg: $input-padding-y;
$input-height-sm: 26px;
$input-padding-x-sm: $input-padding-x;
$input-padding-y-sm: 0;
// focus shadows
$btn-focus-box-shadow: $avi-focus-box-shadow;
$input-focus-box-shadow: $avi-focus-box-shadow;
$form-select-focus-box-shadow: $avi-focus-box-shadow;
$form-check-input-focus-box-shadow: $avi-focus-box-shadow;
// checkboxes, radios & switches
$form-check-input-border: 1px solid $purple-dark;
$form-check-input-checked-bg-color: $secondary;
$form-check-label-color: $shark;
$form-check-input-checked-bg-color: $deep-blue;
$form-switch-color: $secondary;
$form-switch-focus-color: $dark-blue-700;
$input-padding-y: 0.4067rem;
$input-padding-x: 0.5067rem;
$input-color: $shark;
$form-select-indicator-color: $purple-dark;
$form-feedback-valid-color: $leaves;
$form-range-thumb-bg: $purple-dark;
$form-range-thumb-disabled-bg: $asphalt;
$form-range-track-bg: $mercury;
//
// Navbar
//
$navbar-padding-y: 5px;
$navbar-light-brand-color: $shark;
$navbar-light-active-color: $marine;
$navbar-light-color: $shark;
$navbar-light-hover-color: $marine;
$navbar-nav-link-padding-x: 10px;
$navbar-brand-margin-end: 4rem;
$navbar-brand-font-size: 1.375rem;
$nav-link-font-weight: 500;
$nav-link-font-size: 0.875rem;
$navbar-light-brand-hover-color: $navbar-light-brand-color;
$navbar-active-color: $marine;
$navbar-dark-active-color: $white;
$navbar-dark-color: $granite;


//
// Progress
//
$progress-bg: $mercury;
$progress-border-radius: 4px 0 4px 0;
$progress-height: 8px;

//
// Accordion
//
$accordion-border-width: 1px;
$accordion-border-radius: 4px;
$accordion-button-bg: $alabaster;
$accordion-button-active-bg: $alabaster;
$accordion-button-active-color: $shark;
$accordion-button-icon: url('data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M1.41 2.5L0 3.83198L6 9.5L12 3.83198L10.59 2.5L6 6.82659L1.41 2.5Z" fill="#3E4080"/></svg>');
$accordion-icon-width: .9em;
$accordion-button-active-icon: $accordion-button-icon;
$accordion-icon-transform: rotate(0deg);
$accordion-icon-color: $purple-dark;
$accordion-icon-active-color: $purple-dark;
$accordion-button-padding-x: 1.5rem;


//
// Tabs
//
$nav-link-font-weight: 500;
$nav-link-color: $shark;
$nav-tabs-link-active-color: $marine;
$nav-tabs-border-width: 0;

//
// Nav Pills
//
$nav-pills-border-radius: 4px;
$nav-pills-link-active-bg: $dark-blue-700;

//
// Shadows
//
$avi-shadow-color: rgba(0,0,0,0.15);
$box-shadow: 0px 2px 8px $avi-shadow-color;
$box-shadow-sm: 0px 2px 2px $avi-shadow-color;
$box-shadow-lg: 0px 2px 8px $avi-shadow-color;
$avi-box-shadow-hover: 0px 2px 8px $avi-shadow-color;

//
// Spinner
//
$spinner-border-width: 0.25rem;


//
// Alerts
//
$alert-border-radius: 4px;
$alert-border-width: 0;

//
// Badges
//
//$badge-padding-y: .65em;


//
// Pagination
//
$pagination-font-size: .75rem;
$pagination-padding-x: .5rem;
$pagination-padding-y: .375rem;
$pagination-border-color: $purple-500;
$pagination-active-color: $white;
$pagination-active-bg: $dark-blue-700;
$pagination-active-border-color: $dark-blue-700;
$pagination-disabled-color: $primary;
$pagination-disabled-border-color: $pagination-border-color;
$pagination-hover-border-color: $marine;
$pagination-hover-color: $white;
$pagination-hover-bg: $marine;
$pagination-focus-color: $pagination-hover-color;
$pagination-focus-bg: $pagination-hover-bg;
$pagination-focus-box-shadow: $purple-medium;

//
// Breadcrumb
//
$breadcrumb-active-color: $shark;

//
// Card
//
$card-border-radius: 4px;
$card-box-shadow: $box-shadow;
$card-border-color: transparent;
$card-cap-bg: transparent;


//
// List Group
//
$list-group-border-color: $dark-blue-200;


//
// Tables
//
$table-cell-padding-y: .625rem;
$table-cell-padding-x: .75rem;
$table-color: $shark;
$table-th-font-weight: 500;
$table-border-color: $cloud;
$table-hover-bg: $cloud;
$table-active-bg: $deep-blue;
$table-active-color: $white;

//
// Modal
//
$modal-header-border-color: $gray-medium;
$modal-header-border-width: 1px;
$modal-content-border-width: 0;
$modal-content-box-shadow-xs: $box-shadow;
$modal-content-box-shadow-sm-up: $box-shadow;
$modal-footer-border-width: 0;
$modal-backdrop-bg: $abyss;
$modal-backdrop-opacity: .6;

//
// Tooltip
//
$tooltip-bg: $white;
$tooltip-color: $shark;
$tooltip-font-size: 12px;
$tooltip-opacity: 1;
$tooltip-padding-x: .8rem;
$tooltip-padding-y: .4rem;

//
// Popovers
//
$popover-header-bg: $white;
$popover-border-radius: 4px;
$popover-border-width: 0px;
$popover-font-size: 12px;
$popover-box-shadow: $box-shadow;

//
// Toast
//
$toast-color: $shark;
$toast-header-color: $shark;
$toast-border-radius: 4px;
$toast-border-width: 0px;
