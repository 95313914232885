
.btn {
  text-transform: uppercase;
  letter-spacing: 0.47px;
  // fixme Overwriting $btn-focus-box-shadow in variables without setting the css variable does not work without setting it explicitly.
  --avi-btn-focus-box-shadow: #{$btn-focus-box-shadow};
}

 // TODO: really customize them?
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @if $color == "light" {
      @include button-variant(
                      $value,
                      $value,
              $hover-background: $granite,
              $hover-border: $granite,
              $active-background: $anthracite,
              $active-border: $anthracite,
              $active-color: $white
      );
    } @else if $color == "dark" {
      @include button-variant(
                      $value,
                      $value,
              $hover-background: $anthracite,
              $hover-border: $anthracite,
              $active-background: $pitch-black,
              $active-border: $pitch-black
      );
    } @else {
      @if $color == "primary" {
        @include button-variant(
                        $value,
                        $value,
                $hover-color: $white,
                $hover-background: $dark-blue-500,
                $hover-border: $dark-blue-500,
                $active-background: $dark-blue-700,
                $active-border: $dark-blue-700
        );
      } @else if $color == "secondary" {
      background: $white;
      @include button-outline-variant(
                      $primary,
              $color-hover: $secondary,
              $active-background: $white,
              $active-border: $secondary
      );
    } @else {
        @include button-variant(
                        $value,
                        $value,
                $hover-background: shade-color($value, 20%),
                $hover-border: shade-color($value, 20%),
                $active-background: shade-color($value, 40%),
                $active-border: shade-color($value, 40%),
                $active-color: $white
        );
      }
    }
  }
}

.btn-group > .btn:hover{
  z-index: 0;
}

