.btn-tertiary {
  @include button-variant(
          $background: transparent,
          $border: transparent,
          $color: $shark,
          $hover-background: $mercury,
          $hover-border: $mercury,
          $hover-color: $shark,
          $active-background: $gray-medium,
          $active-border: $gray-medium,
          $active-color: $shark,
          $disabled-background: transparent,
          $disabled-border: transparent,
          $disabled-color: rgba($shark, .5)
  );
}
